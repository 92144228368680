import React, { Component, Fragment } from "react"
import Cookie from 'universal-cookie'

class BlogContent extends Component {
    constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: null,
      html: this.props.html, 
      str: "", 
      load: false,
      expired: "false"
    }
  }

  handleCookies = (consent, html, start) => {
    var s = html.indexOf("<iframe", start)
    var e = html.indexOf("</", s+1)
    var str = html.substring(s, e+10)

    consent.categories.includes("functionality") 
       ? str = str.replace(/ data-src="/g, ' src="') 
       : str = str.replace(/ src="/g, ' data-src="' ) + '<br />Accept "functionality" cookies to display this video.'

    this.setState({ html: html.substring(0, s) + str }) 
}

  componentDidMount() {
    const cookie = new Cookie()
    const consent = cookie.get("CookieScriptConsent")
    //console.log(consent.categories) 

    
    this.handleCookies(consent, this.props.html, 0)
  }

  render() {
    return (
      <Fragment key="1"> 
      {console.log(this.state.html)} 
      <div dangerouslySetInnerHTML={{ __html: this.state.html }} />      
      </Fragment>
    )
  }
}

export default BlogContent