import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import BlogContent from "../components/parseHTML"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.frontmatter.author} />
      <div className="content" style={{padding: "0 0 1.5rem 0rem"}}>
        <h1>{post.frontmatter.title}</h1>
        <h3>Author: {post.frontmatter.author}</h3>
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        <BlogContent html={post.html} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug }}, frontmatter: {template: { eq: "blog" }}) {
      html
      frontmatter {
        title
        author
      }
    }
  }
`